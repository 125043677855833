import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/posts', params)
  },

  list(params) {
    return Api().get('/api/posts', {
      params: params,
    })
  },
  delete(id) {
    return Api().delete('/api/posts/' + id)
  },
  update(id, body) {
    return Api().put('/api/posts/' + id, { body })
  },
}
